<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Sigle</label>
        <input
          type="text"
          name="sigle"
          v-model='form.sigle'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('sigle')"
          v-text="form.errors().get('sigle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label  class="req">Dénomination</label>
        <input
          type="text"
          name="denomination"
          v-model='form.denomination'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('denomination')"
          v-text="form.errors().get('denomination')"
          class="errorMsg">
        </span>
      </div>
      <!-- <div class="form-group">
        <label class="req">Description</label>
        <textarea
          rows="3"
          name="description"
          v-model='form.description'
          class="form-control"
          aria-label=".form-control-lg example"
        >
        </textarea>
        <span
          v-if="form.errors().has('description')"
          v-text="form.errors().get('description')"
          class="errorMsg">
        </span>
      </div> -->
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
  @import './banques.css';
  form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import form from 'vuejs-form'
import {mapActions,mapGetters} from "vuex"
export default {
  name: "CreateBanques",
  data: () => ({
    form: form({
      sigle: '',
      denomination: ''
    })
      .rules({
        sigle: 'required',
        denomination: 'required'
      })
      .messages({
        'sigle.sigle': 'Champ requis!',
        'denomination.denomination': 'Champ requis!'
      })
  }),
  watch: {
    // ['form.data']: {
    //   deep: true,
    //   immediate: false,
    //   handler: 'onFormChange'
    // },
    msgSuccessBanque(){
      if (this.msgSuccessBanque) {
        this.form.sigle=""
        this.form.denomination=""
        // this.$emit("banque",{id:this.msgSuccessBanque.data.id,type:"frombanq"})
      }
    }
  },
  computed: {
    ...mapGetters(['msgFailBanque','msgSuccessBanque'])
  },
  methods: {
    ...mapActions(["postBanques"]),
    onFormChange() {
      this.form.validate()
    },
    submit() {
      if (!this.form.validate().errors().any()) {
        this.postBanques(this.form.data)
      }
    }
  }
}
</script>
