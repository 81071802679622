<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Paramètres > Banque</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des banques
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="table_action">
        <div class="div_left">
          <button class="btn btn-blue"
                  data-toggle="modal" 
                  data-target="#exampleModal"
                  @click="btn_stat='banque'">
            Ajouter une banque
          </button>
        </div>
        <div class="div_right"></div>
      </div>
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <div class="tab_holder">
        <div
          class="col_table"
          v-if="banques.donnees"
        >
          <div class="col_header">
            <div class="row">
              <div class="col-3">Libellé</div>
            </div>
          </div>
          <div
            class="row no-mr"
            v-for="(banque,rubkey) in banques.donnees"
            :key="rubkey"
          >
            <div  class="col-md-12">
              <div class="row light_row">
                <div class="col-md-8 ">
                  <label style="font-size:0.9em ;">{{banque.denomination}} ({{banque.sigle}})</label>
                </div>
                <div class="col-md-4 text-right">
                  <button class="btn btn-blue mr-1"
                          @click="btn_stat='compte_bancaire',id_banque=banque.id"
                          data-toggle="modal" 
                          data-target="#exampleModal">+</button>
                  <button type="button"
                          @click="btn_stat='mod_banque',bank=banque"
                          data-toggle="modal" 
                          data-target="#exampleModal" 
                          class="btn btn-blue mr-1">
                    <i class="flaticon-pencil"></i>
                  </button>
                  <!-- <button type="button"
                          @click="btn_stat='del_banque',id_banque=banque.id"
                          data-toggle="modal" 
                          data-target="#exampleModal" 
                          class="btn btn-blue">
                    <i class="flaticon-delete"></i>
                  </button> -->
                </div>
              </div>
              <template v-if="banque.compte_bancaires.length>0">
                <div class="row alter"
                     v-for="(compte_bancaire,actkey) in banque.compte_bancaires"
                     :key="actkey"
                >
                  <div class="col-md-2">Compte n° :</div>
                  <div class="col-md-8">{{compte_bancaire.numero}}</div>
                  <div class="col-md-2 text-right tb_row">
                    <button type="button"
                            @click="btn_stat='mod_compte_bancaire',id_act=compte_bancaire.id"
                            data-toggle="modal" 
                            data-target="#exampleModal" 
                            class="btn btn-blue mr-1">
                      <i class="flaticon-pencil"></i>
                    </button>
                    <!-- <button type="button"
                            @click="btn_stat='del_compte_bancaire',id_act=compte_bancaire.id"
                            data-toggle="modal" 
                            data-target="#exampleModal"
                            class="btn btn-blue">
                      <i class="flaticon-delete"></i>
                    </button> -->
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" 
         id="exampleModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" 
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="btn_stat=='banque'"
                class="modal-title" 
                id="exampleModalLabel">Ajouter Banque</h5>
            <!-- <h5 v-else-if="btn_stat=='del_banque'"
                class="modal-title" 
                id="exampleModalLabel">Supprimer Banque</h5> -->
            <h5 v-else-if="btn_stat=='mod_banque'"
                class="modal-title" 
                id="exampleModalLabel">Modifier Banque</h5>
            <!-- <h5 v-else-if="btn_stat=='del_compte_bancaire'"
                class="modal-title" 
                id="exampleModalLabel">Supprimer Activité</h5> -->
            <h5 v-else-if="btn_stat=='mod_compte_bancaire'"
                class="modal-title" 
                id="exampleModalLabel">Modifier compte bancaire</h5>        
            <h5 v-else
                class="modal-title" 
                id="exampleModalLabel">Ajouter compte bancaire</h5>
            <button type="button" 
                    class="close"
                    ref="modal_button" 
                    data-dismiss="modal" 
                    aria-label="Close"
                    @click="destroyComponent()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <CreateBanques @banque="type => (id = type)"
                           v-if="btn_stat=='banque'"/>
            <UpdateBanques :id_mod_banque="bank"
                           @upbanque="msg => (instruction = msg)"
                           v-if="btn_stat=='mod_banque'"/>
            <UpdateCompteBancaire :id_compte_bancaire="id_act"
                                  @upcompte_bancaire="msg => (instruction = msg)"
                                  v-if="btn_stat=='mod_compte_bancaire'"/>
            <CreateCompteBancaire :id_banque="id_banque"
                                  :id_rub="id.id"
                                  :type="id.type"
                                  v-if="btn_stat=='compte_bancaire'"/>
          </div>
          <!-- <div class="modal-body"
               v-else-if="btn_stat=='del_banque'">
            <p class="text-center"> La suppression de cette banque entrainera la suppression de tous les éléments qui en découlent (activités, marchés, decomptes etc...).</p>
            <p class="text-center">Voulez-vous vraiment effectuer cette action?</p>
            <p class="text-center">
              <button class="btn btn-blue mr-2"
                      @click="delBanque()">Oui</button>
              <button class="btn btn-blue">Non</button>
            </p>
          </div>
          <div class="modal-body"
               v-else-if="btn_stat=='del_compte_bancaire'">
            <p class="text-center"> La suppression de cette activité entrainera la suppression de tous les éléments qui en découlent (marchés, decomptes etc...).</p>
            <p class="text-center">Voulez-vous vraiment effectuer cette action?</p>
            <p class="text-center">
              <button class="btn btn-blue mr-2"
                      @click="delActivite()">Oui</button>
              <button class="btn btn-blue">Non</button>
            </p>
          </div> -->
          
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import './banques.css';

</style>
<script>
import CreateBanques from "./AjoutBanques"
import CreateCompteBancaire from "./AjoutCompteBancaire"
import UpdateCompteBancaire from "./ModifierCompteBancaire"
import UpdateBanques from "./ModifierBanques"

import Notif from "@/components/shared/Toast"

import {mapActions,mapMutations, mapGetters} from "vuex"
export default {
  name: "Banques",
  components:{
    CreateBanques,
    CreateCompteBancaire,
    UpdateBanques,
    UpdateCompteBancaire,
    Notif
  },
  data: () => ({
    btn_stat:"",
    id_banque:"",
    bank:"",
    id_act:"",
    instruction:"",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    id:"",
  }),
  watch:{
    id(){
      if (this.id) {
        this.btn_stat="compte_bancaire"
        this.id_banque = ""
        this.id_banque = this.id
        console.log(this.id_banque)
      }
    },
    instruction(){
      if (this.instruction) {
        this.setBanques("")
        this.Banques()
        this.$refs["modal_button"].click()
      }
    },
    msgSuccessBanque(){
      if (this.msgSuccessBanque) {
        this.notif.message = this.msgSuccessBanque
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        this.bank=""
        this.id_act=""
        this.id_banque=""
        this.btn_stat=""
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessBanque("")
            this.setBanques("")
            this.loadBanques()
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    msgSuccessCompteBancaire(){
      if (this.msgSuccessCompteBancaire) {
        this.notif.message = this.msgSuccessCompteBancaire
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessCompteBancaire("")
            this.setBanques("")
            this.loadBanques()
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    
  },
  created(){
    this.loadBanques()
  },
  computed: {
    ...mapGetters(['banques','errors','msgSuccessBanque','msgFailBanque','msgSuccessCompteBancaire',])
  },
  methods: {
    ...mapActions(["loadBanques",]),
    ...mapMutations(['setBanques','setMsgSuccessCompteBancaire',"setMsgFailBanque","setMsgSuccessBanque",]),
    delActivite(){
      this.deleteActivites({id:this.id_act})
    },
    delBanque(){
      this.deleteBanques({id:this.id_banque})
    },
    destroyComponent(){
      this.btn_stat=""
    }
  }
}
</script>
