<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label>Numéro</label>
        <input
          type="text"
          name="numero"
          v-model='form.numero'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('numero')"
          v-text="form.errors().get('numero')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label for="">Banque</label>
        <select class="form-control"
                v-model="form.banque"
                disabled>
          <option v-for="(banque,bankey) in banqueSansPage.donnees"
                  :key="bankey"
                  :value="banque.id">{{banque.denomination}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">Base:</label>
        <select class="form-control"
                v-model="form.base">
          <option value="SIEGE">Siège</option>
          <option value="PEAGE">Péage</option>
          <option value="PESAGE">Pesage</option>
          <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
        </select>
      </div>
      <div class="form-group">
        <label for="">Goupe de compte:</label>
        <select class="form-control"
                v-model="form.groupe">
          <option value="COMPTES_TRAVAUX_ROUTIERS"
                  v-if="form.base=='SIEGE'">Travaux routiers</option>
          <option value="COMPTES_FONCTIONNEMENT_INVESTISSEMENTS">Fonctionnement & Investissements</option>
          <option value="COMPTES_PROVISION">Domiciliations & provisions</option>
          <option value="COMPTES_RESERVE">Sequestres</option>
          <option value="AUTRES">Caisses</option>
        </select>
      </div>
      <div class="form-group">
        <label class="req">Compte comptable:</label>
        <select class="form-control"
                v-model="form.compte_comptable"
                v-if="planComptes.donnees"
                @change="getIntitule(form.compte_comptable)">
          <option value="">Choisissez un numéro de compte comptable</option>
          <template v-for="(plan, plkey) in planComptes.donnees">
            <option :key="plkey"
                    :value="plan.id"
                    v-if="form.base==plan.base">{{plan.compte}} - {{plan.description}}</option>
          </template>
        </select>
      </div>
      <div class="form-group">
        <label class="req">Intitulé</label>
        <input type="text" 
               class="form-control"
               disabled
               v-model="form.intitule"/>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="button"
          @click="destroyComponent()"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
  @import './banques.css';
  form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import form from 'vuejs-form'
import {mapActions,mapMutations,mapGetters} from "vuex"
export default {
  name: "UpdateCompteBancaire",
  
  props:["id_compte_bancaire"],
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      id: '',
      numero: '',
      banque: '',
      base:'',
      groupe:'',
      intitule:'',
      compte_comptable:''
    })
      .rules({
        id: 'required',
        numero: 'required',
        banque: 'required'
      })
      .messages({
        'id.id': 'Champ requis!',
        'numero.numero': 'Champ requis!',
        'banque.banque': 'Champ requis!'
      })
  }),
  watch: {
    ['form.data']: {
      deep: true,
      immediate: false,
      handler: 'onFormChange'
    },
    compteBancaire(){
      if (this.compteBancaire) {
        // console.log(this.compteBancaire)
        this.form.id= this.compteBancaire[0].id
        this.form.banque= this.compteBancaire[0].banque
        this.form.numero= ""+this.compteBancaire[0].numero+""
        switch (this.compteBancaire[0].base) {
          case 1:
            this.form.base="SIEGE"  
            break
          case 2:
            this.form.base="PEAGE"  
            break
          case 3:
            this.form.base="PESAGE"  
            break
          default:
            this.form.base="AIRE_STATIONNEMENT"  
            break
        }
        this.form.groupe= this.compteBancaire[0].groupe
        this.form.compte_comptable= this.compteBancaire[0].compte_comptable
        this.form.intitule= this.compteBancaire[0].intitule
      }
    },
    msgSuccessCompteBancaire(){
      if (this.msgSuccessCompteBancaire) {
        this.notif.message = this.msgSuccessCompteBancaire.msg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessCompteBancaire("")
            this.$emit("upactivite",{msg:"reload"})
          }.bind(this),
          3000
        )
      }
    },
  },
  created(){
    this.listBanques()
    this.getCompteBancaire(this.id_compte_bancaire)
    this.getListPlanComptes()
  },
  computed: {
    ...mapGetters(['compteBancaire','msgSuccessCompteBancaire',"compteBancaire","banqueSansPage","planComptes"])
  },
  methods: {
    ...mapActions(["putCompteBancaire","listBanques","getCompteBancaire","getListPlanComptes"]),
    ...mapMutations(["setMsgSuccessCompteBancaire"]),
    onFormChange() {
      this.form.validate()
    },
    submit() {
      if (!this.form.validate().errors().any()) {
        switch (this.form.data.base) {
          case "SIEGE":
            this.form.data.base=1  
            break
          case "PEAGE":
            this.form.data.base=2  
            break
          case "PESAGE":
            this.form.data.base=3  
            break
          default:
            this.form.data.base=4  
            break
        }
        this.putCompteBancaire(this.form.data)
      }
    },
    destroyComponent(){
      this.$parent.$refs["modal_button"].click()
    },
    getIntitule(id){
      this.planComptes.donnees.forEach(compte => {
        if (compte.id==id) {
          this.form.intitule=compte.description
        }
      })
    }
  }
}
</script>
